import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Header from '../components/header'
import Breadcrumb from '../components/breadcrumb'
import Footer from '../components/footer'
import Seo from '../components/seo'
import pathspliter from '../functions/pathspliter'
import {graphql} from 'gatsby'
// import {Link} from 'gatsby'

class Contact extends Component {
  constructor(props) {
  super(props)

  this.state ={
    name: '',
    nameerror:'',
    email: '',
    emailerror:'',
    message: '',
    messageerror:'',
    loading:false,
    netresponse: ''
  }
}

handleChange = (e) => {
  console.log(e.target.name)
  this.setState({
    [e.target.name]:e.target.value
  })
}

handleSubmit = (e) => {
  e.preventDefault()
  var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

  var {name,email,message} = this.state
  console.log(name, email, message)
  if(!name) {
    this.setState({
      nameerror: this.props.pageContext.langcode === 'EL'? 'KENO ONOMA' : 'NOT NULL'
    })
  }
  else {
    this.setState({
      nameerror: ''
    })
  }

  if(!email.match(pattern)) {
    this.setState({
      emailerror: this.props.pageContext.langcode === 'EL'? 'ΛΑΘΟΣ EMAIL' : 'INVALID EMAIL'
    })
  }
  else {
    this.setState({emailerror:''})
  }
  if(!message) {
    this.setState({
      messageerror: this.props.pageContext.langcode === 'EL'? 'KENO ΜΗΝΥΜΑ' : 'NOT NULL'
    })
  }
  else {
    this.setState({messageerror:''})
  }

  if(name && message && email.match(pattern) ) {
    console.log('PASS')

    var data = {

              "webform_id":"contact",



                              "webform_data" : {

                                              "name" : this.state.name,

                                              "email" : this.state.email,



                                              "message" : this.state.message

                              }



}

this.setState({loading:true, name:'', message:'', email:''})
var url = `${this.props.pageContext.baseurl}/graphql/wd_webform/submit`
fetch(url, {
 method:'post',
 mode: 'cors',

 body:JSON.stringify(data)
})
.then(function(response) {


return response.json()


})
.then(res => {
 console.log('message sending in fetch', res)
 if(res.data.submission_id) {
   this.setState({loading:false, netresponse:'Message sent'})
 }
 else {
    this.setState({loading:false, netresponse:'Network Error'})
 }


})
.catch((error) => {
    this.setState({loading:false, netresponse:'Network Error'})
   console.log("in error", error)

})
  }



}

  render() {

    return (<div>
      <Seo canonical={`${this.props.pageContext.baseurl}${this.props.path}`} metainfo={this.props.data.drupal.metainfo.entities[0].entityMetatags} />
      <Header contactinfo={this.props.pageContext.contactinfo.entities[0]} langcode={this.props.pageContext.langcode} langcontainer={this.props.pageContext.langcontainer} path={this.props.path} menu={this.props.pageContext.menu}/>
      <Breadcrumb path={pathspliter(this.props.path)} title={this.props.pageContext.title}/>
      <main>
        <section className="mapcontainer">
              <div className="mapouter"><div style={{height:"500px"}} className="gmap_canvas"><iframe title="embedgooglemap" width="100%" height="500" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.853646135146!2d23.755628315968302!3d37.98054467972244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bd56ce40d8ef%3A0x6788274dcf691af0!2zzprOtc-BzrHPg86_z43Ovc-Ezr_PgiAxNCwgzpHOuM6uzr3OsSAxMTUgMjg!5e0!3m2!1sel!2sgr!4v1579019583179!5m2!1sel!2sgr" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://www.embedgooglemap.org"> </a></div>
            </div>
            </section>
         <section className="py-6 contact-form">
           <div  className="ys-section vc_row wpb_row vc_row-fluid vc_custom_1543874286910 vc_row-has-fill vc_general vc_parallax vc_parallax-content-moving">
             <div className="wpb_column ">
               <div className="vc_column-inner ">
                 <div className="wpb_wrapper">
                   <div role="form" className="wpcf7">
                     <div className="screen-reader-response"></div>
                     <Container>
                       <Row>
                         <Col md={8}>
                           <form className="wpcf7-form">

                             <div className="content-element5 text-color-light">
                               <div className="section-pre-title">{this.props.pageContext.langcode === 'EN'? "HAVE A QUESTION?": "ΕΧΕΤΑ ΚΑΠΟΙΑ ΑΠΟΡΙΑ?"}</div>
                               <h2 className="section-title">{this.props.pageContext.langcode === 'EN'? "Contact Us": "Επικοινωνία"}</h2>
                             </div>
                             <p className="content-element3" style={{
                                 color: "#fff"
                               }}>{this.props.pageContext.langcode === 'EN'? "Feel free to send us any questions you may have. We are happy to answer them.": "Στείλτε μας οποιαδήποτε απορία έχετε, θα χαρούμε να σας απαντήσουμε."}</p>
                             <div className="contact-item var2">
                               <label htmlFor="name">{this.props.pageContext.langcode === 'EN'? "Name": "Όνομα"}</label>
                               <span className="wpcf7-form-control-wrap your-name">
                                 <input type="text" name="name" value={this.state.name} onChange={this.handleChange} size="40" className={`wpcf7-form-control wpcf7-text ${this.state.nameerror? "redborder" : ""} `} aria-required="true" aria-invalid="false"/>
                                 <div style={{color:'red'}}>{this.state.nameerror}</div>
                               </span>
                             </div>
                             <div className="contact-item var2">
                               <label htmlFor="email">Email</label>
                               <span className="wpcf7-form-control-wrap your-name">
                                 <input type="email" name="email" value={this.state.email} onChange={this.handleChange} size="40" className={`wpcf7-form-control ${this.state.emailerror? "redborder" : ""} `} aria-required="true" aria-invalid="false"/>
                                 <div style={{color:'red'}}>{this.state.emailerror}</div>
                               </span>
                             </div>
                             {/* <div className="contact-item var2">
                               <label>Subject</label>
                               <span className="wpcf7-form-control-wrap your-subject"><input type="text" name="your-subject" value="" size="40" className="wpcf7-form-control wpcf7-text" aria-invalid="false"/></span>
                             </div> */}
                             <div className="contact-item var2">
                               <label htmlFor="message">{this.props.pageContext.langcode === 'EN'? "Message": "Μήνυμα"}</label>
                               <span className="wpcf7-form-control-wrap your-message">
                                 <textarea name="message" value={this.state.message} onChange={this.handleChange} cols="10" rows="5" className={`wpcf7-form-control wpcf7-textarea ${this.state.messageerror? "redborder" : ""} `}    aria-invalid="false"></textarea>
                                 <div style={{color:'red'}}>{this.state.messageerror}</div>
                               </span>
                             </div>
                             <div className="contact-item var2"><button onClick={this.handleSubmit}  className="wpcf7-form-control wpcf7-submit btn btn-style-3">{this.props.pageContext.langcode === 'EN'? "Submit": "Υποβολη"}</button>

                             </div>
                             <div className="wpcf7-response-output"><h4>{this.state.netresponse}</h4></div>
                           </form>

                         </Col>
                         <Col md={4}>
                            <div className="contact-info">
                              <div className="px-3 contact-inner">
                                    <h3 className="pt-4">{this.props.pageContext.langcode === 'EN'? "Athens": "Αθήνα"}</h3>
                                      <div className="mb-3">
                                        <span> <i style={{color: "#e883ae"}} className=" fas fa-map-marker-alt"></i>{this.props.pageContext.langcode === 'EN'? " Address": " Διεύθυνση"}</span>
                                        <h5 className="adress">{this.props.pageContext.contactinfo.entities[0].fieldAddress}</h5>
                                      </div>
                                      <div className="mb-3">
                                         <span> <i style={{color: "#e883ae"}} className=" fas fa-phone"></i>{this.props.pageContext.langcode === 'EN'? " Phone Number": " Τηλέφωνο"}</span>
                                         <h5 className="phonenumber">{this.props.pageContext.contactinfo.entities[0].fieldPhone}</h5>
                                      </div>

                                      <div className="mb-3">
                                         <span> <i style={{color: "#e883ae"}} className=" fas fa-at"></i> E-mail</span>
                                         <h5 className="email-add"><a href={`mailto:${this.props.pageContext.contactinfo.entities[0].fieldEmail}`}>{this.props.pageContext.contactinfo.entities[0].fieldEmail}</a></h5>
                                      </div>

                                      {/* <div className="mb-3">
                                         <span> <i style={{color: "#e883ae"}} className=" fas fa-clock"></i> Opening hours</span>
                                         <h5 className="openhours">Monday-Tuesday 10:00-22:00</h5>
                                      </div> */}

                              </div>


                            </div>

                         </Col>

                       </Row>

                     </Container>

                   </div>
                 </div>
               </div>
             </div>
             <div className="vc_parallax-inner skrollable skrollable-between" data-bottom-top="top: -50%;" data-top-bottom="top: 0%;"></div>
           </div>
         </section>

      </main>
      <Footer social={this.props.pageContext.social} langcode={this.props.pageContext.langcode}/>
    </div>);
  }

}

export default Contact;


export const query = graphql`
  query($langcode: DRUPAL_LanguageId) {
    drupal {



      metainfo:nodeQuery(filter: {conditions: [{field: "type", value: "contact"}]}) {
        entities(language:$langcode) {
          ... on DRUPAL_NodeContact {

            entityMetatags {
              value
              key
            }

          }
        }
      }









}
  }
`
